import React from 'react'
import { Helmet } from 'react-helmet'

import Seo from '../../containers/Seo'
import Page from '../../containers/DraggerGame'
import bonnechance from '../../games/bonne-chance.json'

const Game = (props) => {
  return (
    <>
      <Seo.BonneChance />
      <Page data={bonnechance} {...props} />
    </>
  )
}

export default Game
